<template>
    <div class="contaners_share">
        <img style="width:100%;height:2000px" src="../../assets/backgroundicon.png"/>
        <div class="content_share_con">
            <div class="header_contract">
                <div class="contranct_text1" @click="goClone">
                    <img src="../../assets/cloneBtn.png"/>
                    <div>返回上一页</div>
                </div>
                <!-- <div class="contranct_text">详细操作流程查看></div> -->
            </div>
            <div v-html="settingDate.config.shareholder_online_flow"></div>
        </div>
    </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import { getSetting } from "@/api/common";
    export default{
        data(){
            return {
                 settingDate:{
                     config:{}
                 }
            }
        },
        created(){
            document.title = "合同填写";
            this.getConfig();
        },
        methods: {
        mineMessage() {
            // wx.closeWindow();
            this.$router.push("../")
        },
          getConfig() {
                getSetting().then(res=>{
                    console.log(res.status)
                    if(res.code==0){
                        this.settingDate = res.data;
                        console.log(this.settingDate.config.shareholder_online_flow);
                        this.settingDate.config.shareholder_online_flow = this.settingDate.config.shareholder_online_flow.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
                        this.$forceUpdate()
                    }
                })
            },
            goClone(){
                this.$router.go(-1)
            }
        }    
    }
</script>
<style scoped>
    .contaners_share{
        width: 100%;
        height:100%;
        position: relative;
        font-size: 30px;
        color: #DEB77A;
    }
    .wscnph{
        width: 100%;
    }
    .content_share_con{
        position: absolute;
        top: 0;left: 46px;
        width:666px
    }
    .messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  margin-top: 20px;
  color: #fff;
  position: absolute;
  top: 60px;right: 10px
}
.header_contract{
    display: flex;
    justify-content:space-between;
    margin-top:10px 
}
.header_contract div{
    display: flex
}
.header_contract img{
    width: 28px;height: 28px;
    margin-right: 10px
}
.contranct_text1{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;;
    margin-top: 20px
}
.contranct_text{
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #DEB77A;
    opacity: 1;
    margin-top: 25px
}
.contranct_box{
    width: 515px;height: 567px;
    margin:291px auto 0;
    position: relative;
}
.contranct_box img{
    width:100%;
    height:100%
}

.formSubmit{
    position: absolute;
    top: 87px;
    left: 0
}
</style>